<template>
  <div class="main-content">
    <b-card>
      <good-data-table 
        ref="attendances" 
        :button-label="$t('Add')"
        @add-new-info="New_attendance" 
        :columns="columns" :no-serial="true"
        :total-column="'response.responseData.total'" 
        :data-column="'response.responseData.data'"
        :api-endpoint="'/hrm/attendances'"
      />
    </b-card>

    <validation-observer ref="Create_Attendance">
      <b-modal v-model="attendanceModal" hide-footer size="md" id="Modal_attendance" :title="editmode ? $t('Edit') : $t('Add')">
        <b-form @submit.prevent="Submit_Attendance">
          <b-row>
            <!-- Employee -->
            <b-col md="12">
              <validation-provider name="Employee" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Employee') + ' ' + '*'">
                  <v-select :class="{ 'is-invalid': !!errors.length }" :state="errors[0] ? false : (valid ? true : null)"
                    v-model="attendance.employee_id" class="required" required @input="Selected_Employee"
                    :placeholder="$t('Choose Employee')" :reduce="label => label.value"
                    :options="employees.map(employees => ({ label: employees.username, value: employees.id }))" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Date  -->
            <b-col md="12">
              <validation-provider name="Date" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Date') + ' ' + '*'">
                  <Datepicker id="date" name="date" :placeholder="$t('Enter Attendance Date')" v-model="attendance.date"
                    input-class="form-control back_important" format="yyyy-MM-dd"
                    @closed="attendance.date = formatDate(attendance.date)">
                  </Datepicker>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Time_In  -->
            <b-col md="6">
              <validation-provider name="Time_In" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Time In') + ' ' + '*'">
                  <b-form-timepicker v-model="attendance.clock_in" locale="en" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Time_In  -->
            <b-col md="6">
              <validation-provider name="Time Out" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Time Out') + ' ' + '*'">
                  <b-form-timepicker v-model="attendance.clock_out" locale="en" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12" class="mt-3">
              <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i
                  class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
              <div v-once class="typo__p" v-if="SubmitProcessing">
                <div class="spinner sm spinner-primary mt-3"></div>
              </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import VueClockPicker from '@pencilpix/vue2-clock-picker';
import Datepicker from 'vuejs-datepicker';
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'

export default {
  components: {
    GoodDataTable, Datepicker, VueClockPicker
  },

  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      attendanceModal: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      employees: [],
      attendances: [],
      attendance: {
        employee_id: "",
        date: "",
        clock_in: "",
        clock_out: "",
      },

      office_shift: {
        name: "",
        monday_in: "",
        monday_out: "",
        tuesday_in: "",
        tuesday_out: "",
        wednesday_in: "",
        wednesday_out: "",
        thursday_in: "",
        thursday_out: "",
        friday_in: "",
        friday_out: "",
        saturday_in: "",
        saturday_out: "",
        sunday_in: "",
        sunday_out: "",
      },
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Employee"),
          field: "employee_username",
          
        },
        {
          label: this.$t("Date"),
          field: "date",
        },
        {
          label: this.$t("Time In"),
          field: "clock_in",
        },
        {
          label: this.$t("Time Out"),
          field: "clock_out",
        },
        {
          label: this.$t("Work Duration"),
          field: "total_work",
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Attendance(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Attendance(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },
  methods: {

    formatDate(d) {
      var m1 = d.getMonth() + 1;
      var m2 = m1 < 10 ? '0' + m1 : m1;
      var d1 = d.getDate();
      var d2 = d1 < 10 ? '0' + d1 : d1;
      return [d.getFullYear(), m2, d2].join('-');
    },


    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //------------- Submit Validation Create & Edit attendance
    Submit_Attendance() {
      this.$refs.Create_Attendance.validate().then(success => {
        if (!success) {
          showErrorNotification(this, "Please fill the form correctly")
        } else {
          if (!this.editmode) {
            this.Create_Attendance();
          } else {
            this.Update_Attendance();
          }
        }
      });
    },

    //------------------------------ Show Modal (Create attendance) -------------------------------\\
    New_attendance() {
      this.reset_Form();
      this.editmode = false;
      this.attendanceModal = true;
    },

    //------------------------------ Show Modal (Update attendance) -------------------------------\\
    Edit_Attendance(attendance) {
      this.editmode = true;
      this.reset_Form();
      this.Get_employees_by_company();
      this.attendance = attendance;
      this.attendanceModal = true;
    },

    Selected_Company(value) {
      this.employees = [];
      this.attendance.employee_id = "";
      this.Get_employees_by_company();
    },

    Selected_Employee(value) {
      if (value === null) {
        this.attendance.employee_id = "";
      }
    },

    //---------------------- Get_employees_by_company ------------------------------\\

    Get_employees_by_company() {
      axios
        .get(apiUrl+"/core/get_employees_by_company")
        .then(({ data }) => (this.employees = data));
    },


    //------------------------------- Create attendance ------------------------\\
    Create_Attendance() {

      this.SubmitProcessing = true;
      axios
        .post(apiUrl+"/attendances", {
          employee_id: this.attendance.employee_id,
          date: this.attendance.date,
          clock_in: this.attendance.clock_in,
          clock_out: this.attendance.clock_out,

        })
        .then(response => {
          this.SubmitProcessing = false;
          this.$refs.attendances.loadItems()
          this.attendanceModal = false;
          showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update attendance ------------------------\\
    Update_Attendance() {
      this.SubmitProcessing = true;
      axios
        .put(apiUrl+"/attendances/" + this.attendance.id, {
          employee_id: this.attendance.employee_id,
          date: this.attendance.date,
          clock_in: this.attendance.clock_in,
          clock_out: this.attendance.clock_out,
        })
        .then(response => {
          this.SubmitProcessing = false;
          this.$refs.attendances.loadItems()
          this.attendanceModal = false;
          showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
      this.attendance = {
        employee_id: "",
        date: "",
        clock_in: "",
        clock_out: "",
      };
    },

    //------------------------------- Delete attendance ------------------------\\
    Remove_Attendance(props) {
      axios
        .delete(apiUrl+"/attendances/" + props.id)
        .then(() => {
          this.$refs.attendances.loadItems()
          showSuccessNotification(this, 'Deleted successfully!');
        })
        .catch(() => {
          showErrorNotification(this, "Something went wrong while deleting item!");
        });

    }

  },

  //----------------------------- Created function-------------------\\

  mounted () {
    this.Get_employees_by_company();
    this.$refs.attendances.loadItems()
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
